// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

//-----------------------|| APEXCHART ||-----------------------//

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}
.select__control{
    background-color: #ffffff;
    color: #616161;
    border-radius: 4px;
    box-shadow: none;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4375em;
    font-family: 'Roboto',sans-serif;
    color: #616161;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    background: #fafafa;
    padding: 7px 14px;
}
.basic-multi-select{
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
    background: #fafafa;
    border-color: rgba(0, 0, 0, 0.23);
}
//-----------------------|| PERFECT SCROLLBAR ||-----------------------//

.hover-text-nav:hover{
    color: #FFF;
}

.text-center{
    text-align: center !important;
}

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

//-----------------------|| ANIMATION KEYFRAMES ||-----------------------//

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
